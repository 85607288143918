import React from "react"
import Headbar from "./headbar"
import Menubar from "./menubar"
import { Helmet } from "react-helmet"

const AppLayout = ({ children, title, path, isBackButton, isMenu = true }) => {
  return (
    <div className="flex ">
      <Helmet>
        <meta charSet="utf-8" />
        <title>acadmi</title>
        {/* viewport-fit=cover */}
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1 maximum-scale=1, user-scalable=no"
        />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="HandheldFriendly" content="true" />

        {/* <!-- possible content values: default, black or black-translucent --> */}
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
      </Helmet>
      <div className="hidden md:w-1/5 ">sidebar</div>
      <div className="flex flex-col flex-1 h-screen ">
        <Headbar title={title} path={path} isBackButton={isBackButton} />
        <div className="z-10 flex flex-1 w-full py-16 mb-16 overflow-x-hidden overflow-y-scroll bg-gray-100 dark:bg-black overscroll-contain hideScrollBar">
          <div className="flex flex-col w-full">{children}</div>
        </div>
        {isMenu && <Menubar path={path} />}
      </div>
      <div className="hidden md:w-2/6 ">sidebar</div>
    </div>
  )
}

export default AppLayout
