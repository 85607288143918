import React from "react"
import menulist from "./menulist"
import IconWrapper from "./iconWrapper"
import { navigate } from "gatsby"

const Menubar = ({ path }) => {
  return (
    <div className="fixed bottom-0 left-0 right-0 z-50 border-t">
      <nav className="flex items-center justify-around w-full bg-white ">
        {menulist.map(item => (
          <button
            key={item.text}
            onClick={() => navigate(`/app/${item.uri}`)}
            className="flex flex-col items-center justify-center px-4 pt-2 pb-6 text-gray-500 cursor-pointer select-none focus:outline-none"
          >
            <IconWrapper
              component={
                path === `/app/${item.uri}/` ? item.iconDark : item.iconLight
              }
              className={`w-5 h-5 text-black `}
            />
            <p className={`mt-1 capitalize text-xs `}>{item.text}</p>
          </button>
        ))}
      </nav>
    </div>
  )
}

export default Menubar
