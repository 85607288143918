import {
  AiFillHome,
  AiOutlineHome,
  AiFillPieChart,
  AiOutlinePieChart,
} from "react-icons/ai"
import {
  RiFileListFill,
  RiFileListLine,
  RiPencilFill,
  RiPencilLine,
} from "react-icons/ri"
import { BsFillCollectionFill, BsCollection } from "react-icons/bs"

const MenuList = [
  { text: "home", uri: "home", iconLight: AiOutlineHome, iconDark: AiFillHome },
  {
    text: "exams",
    uri: "examlist",
    iconLight: RiFileListLine,
    iconDark: RiFileListFill,
  },
  {
    text: "premium",
    uri: "premium",
    iconLight: BsCollection,
    iconDark: BsFillCollectionFill,
  },
  {
    text: "analysis",
    uri: "analysis",
    iconLight: AiOutlinePieChart,
    iconDark: AiFillPieChart,
  },
  {
    text: "articles",
    uri: "articles",
    iconLight: RiPencilLine,
    iconDark: RiPencilFill,
  },
]

export default MenuList
